
 
import React, { useContext, useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { TableContext } from "../context";
import { toast } from "react-toastify";
 
function Row(props) {
  const {
    row,
    checkedRows,
    setCheckedRows,
    isciowner,
  } = props;
  // Function to handle checkbox change
  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      // Add row information to selectedRows
      setCheckedRows((prevSelectedRows) => [...prevSelectedRows, row]);
    } else {
      // Remove row information from selectedRows
      setCheckedRows((prevSelectedRows) =>
        prevSelectedRows.filter(
          (checkedRows) =>
            checkedRows.repository_name !==
            row.repository_name
        )
      );
    }
    console.log("checkedRows", checkedRows);
  };
 
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "none" } }}>
        <TableCell
          component="th"
          scope="row"
          align="center"
          sx={{ width: "450px" }}
        >
          {row.repository_name.split('/')[1] || row.repository_name}
        </TableCell>
        <TableCell align="center">
          {row.primary_language}
        </TableCell>
        <TableCell align="center">
          {row.last_commit_author}
        </TableCell>
        <TableCell align="center">
          {row.link_to_repo}
        </TableCell>
 
        {isciowner === true ? (
          <>
            <TableCell align="center">
              {" "}
              <input type="checkbox" onChange={handleCheckboxChange}></input>
            </TableCell>
            <TableCell align="center" />
          </>
        ) : (
          <>
            <TableCell align="center">
                {" "}
            </TableCell>
            <TableCell align="center" />
          </>
        )}
      </TableRow>
    </React.Fragment>
  );
}
 
 
function Table10({ data10, setProgress, fetchRepoData }) {
  console.log(data10);
  const data10Formatted = data10?.[0]?.suggested_data.map(repo => [
    repo.nameWithOwner,
    repo.primaryLanguage,
    repo.lastCommitAuthor,
    repo.url
  ]);
  const { applicationName, graphData, repoCiOwner } = useContext(TableContext);
  const data = data10Formatted;
  console.log(data);
  const [checkedRows, setCheckedRows] = React.useState([]);
 
  const [sortState10, setSortState10] = useState({ columnIndex: null, order: null });
  const handleSort = (colIndex, direction) => {
    setSortState10({
      columnIndex: colIndex,
      order: direction,
    });
 
    const tbody = document.querySelector('tbody');
    const rows = Array.from(tbody.querySelectorAll('tr'));
 
    // Group each parent row and its corresponding expandable row as a pair
    const rowPairs = [];
    for (let i = 0; i < rows.length; i++) {
      const parentRow = rows[i];
      const nextRow = rows[i + 1];
      // Check if the next row is expandable (i.e., has colspan attribute)
      if (nextRow && nextRow.querySelector('td[colspan]')) {
        rowPairs.push([parentRow, nextRow]); // Pair the parent row with its expandable row
        i++; // Skip the next row since it's already grouped with its parent
      } else {
        rowPairs.push([parentRow]); // In case there is no expandable row
      }
    }
 
    // Sort only the parent rows based on the selected column
    const sortedPairs = rowPairs.sort((a, b) => {
      const valueA = a[0].children[colIndex].innerText.toLowerCase();
      const valueB = b[0].children[colIndex].innerText.toLowerCase();
 
      // Handle numerical values
      const numA = parseFloat(valueA);
      const numB = parseFloat(valueB);
 
      if (!isNaN(numA) && !isNaN(numB)) {
        return direction === "asc" ? numA - numB : numB - numA;
      }
 
      // String comparison
      if (valueA < valueB) return direction === "asc" ? -1 : 1;
      if (valueA > valueB) return direction === "asc" ? 1 : -1;
      return 0;
    });
 
    // Clear the table body
    while (tbody.firstChild) {
      tbody.removeChild(tbody.firstChild);
    }
 
    // Append the sorted parent rows along with their expandable rows
    sortedPairs.forEach(pair => {
      pair.forEach(row => tbody.appendChild(row));
    });
  };
 
  const handleCertifyClick = async () => {
    setProgress(20);
    const repositoryNames = checkedRows.map(row => row.repository_name).join(', ');
    console.log("Repository Names: ", repositoryNames);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}/createrepomapping`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          applicationName: applicationName,
          repositoryNames: repositoryNames,
          user_name: graphData?.displayName,
        }),
      });
 
      const data = await response.json();
      if (response.ok) {
        console.log("Repositories mapped successfully");
        console.log("Data", data);
        if(Object.keys(data?.successfully_mapped).length > 0){
          toast.success(`Relationship(s) created! Kindly search again to see updated changes`, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        if(data?.already_mapped.length > 0){
          const mapped_repos = data?.already_mapped.join(', ');
          toast.warning(`Relationship already exists between ${mapped_repos} and ${applicationName}`, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        if(data?.not_found.length > 0){
          const absent_repos = data?.not_found.join(', ');
          toast.warning(`Repositories ${absent_repos} not found`, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        console.error("Error mapping repository:", data.error);
        toast.error("Relationship creation failed!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.error("Request failed:", error);
      toast.error("Relationship creation failed!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setProgress(100);
    setCheckedRows([]);
    // Uncheck all checkboxes
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
  };
 
  return (
    <div>
      <div
        style={{
          margin: "auto",
          width: "90%",
          marginTop: "30px",
          // maxHeight:"40vh",
          // overflowY: "auto",
          // maxWidth: "fit-content",
          border: "2px solid #87CEEB",
          display: "flex",
          zIndex: "-10",
        }}
      >
        {/* <div style={{width:"300px"}}>
        <TableCell
                  sx={{ borderBottom:"2px solid #87CEEB",width:"300px",fontSize:"1rem", }}
                  align="center"
                >
                  Filters
                </TableCell>
 
        </div> */}
 
        <TableContainer
          component={Paper}
          sx={{ width: "100%", maxHeight: "400px" }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ "& .MuiTableCell-root": { fontSize: "1rem" } }}
          >
            <TableHead>
              <TableRow>
              {["Repository Name", "Primary Language", "Last Commit Author", "Link to Repository", ""].map((header, index) => (
                  <TableCell
                    key={index}
                    sx={{ backgroundColor: "#87CEEB", color: "white", border: "2px solid #87CEEB" }}
                    align="center"
                  >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                      {header}
                      {index!==4 &&
                      <div style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>
                        {sortState10.columnIndex === index && sortState10.order === "asc" ? (
                          <KeyboardArrowDownIcon
                            style={{ cursor: "pointer", fontSize: "24px", fontWeight: "bold" }}
                            onClick={() => handleSort(index, "desc")}
                          />
                        ) : (
                          sortState10.columnIndex !== index && (
                            <KeyboardArrowUpIcon
                              style={{ cursor: "pointer", fontSize: "16px", fontWeight: "bold" }}
                              onClick={() => handleSort(index, "asc")}
                            />
                          )
                        )}
                        {sortState10.columnIndex === index && sortState10.order === "desc" ? (
                          <KeyboardArrowUpIcon
                            style={{ cursor: "pointer", fontSize: "24px", fontWeight: "bold" }}
                            onClick={() => handleSort(index, "asc")}
                          />
                        ) : (
                          sortState10.columnIndex !== index && (
                            <KeyboardArrowDownIcon
                              style={{ cursor: "pointer", fontSize: "16px", fontWeight: "bold" }}
                              onClick={() => handleSort(index, "desc")}
                            />
                          )
                        )}
                      </div>}
                    </div>
                  </TableCell>
                ))}
                <TableCell
                   
                    sx={{ backgroundColor: "#87CEEB", color: "white", border: "2px solid #87CEEB" }}
                    align="center"
                  ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data !== undefined &&
                Array.isArray(data) &&
                data && data
                .map((repo) => (
                    <Row
                        key={repo[0]}
                        row={{
                            repository_name: repo[0],
                            primary_language: repo[1],
                            last_commit_author: repo[2],
                            link_to_repo: (
                                <a href={repo[3]} target="_blank" rel="noopener noreferrer" style={{ color: "#42b0d5" }}>
                                  {repo[0]} {/* Displaying repository name as link text */}
                                </a>
                            ),
                        }}
                        checkedRows={checkedRows}
                        setCheckedRows={setCheckedRows}
                        isciowner={repoCiOwner}
                    />
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
     {checkedRows?.length > 0 && (
      <div style={{ width: "90%", margin: "auto" }}>
        <button
          className="btn btn-success "
          style={{
            backgroundColor: "#42B0D5",
            borderColor: "#42B0D5",
            float: "right",
            marginTop: "8px",
          }}
          onClick={handleCertifyClick}
        >
          Create Relationship
        </button>
      </div>
    )}
  </div>
);
}
 
export default Table10;
 
 