import React, { useState, useEffect, useContext, useLayoutEffect } from "react";
import "../App.css";
//  import '../helper.js';
// import "../Table.css";
import DeleteConfirmation from "./DeleteConfirmation";
import { all } from "axios";
import { Checkbox } from "@anchor/react-components/dist/lib/components";
import { Button } from "@anchor/react-components";
import { useRef } from "react";
import { TableContext } from "../context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/**
 * Table3 component displays a table with filter options and checkboxes for each row.
 * It fetches data from the server and allows the user to approve or discard changes.
 *
 * @component
 * @example
 * return (
 *   <Table3 />
 * )
 */
/**
 * Table3 component for displaying and managing a table with various functionalities.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {Array} props.table1 - The data to be displayed in the table.
 * @param {number} props.FilterIndex - The index of the first filter column.
 * @param {number} props.FilterIndex2 - The index of the second filter column.
 * @param {Function} props.setshowCommit - Function to set the showCommit state.
 * @param {Function} props.setProgress - Function to set the progress state.
 * @param {Function} props.getData - Function to fetch data from the server.
 *
 * @returns {JSX.Element} The rendered Table3 component.
 */
export default function Table3(props) {
  // State variables for managing component state
  // const {props.table1}=props.props.table1;
  const { graphData, getCount, fetchOwnership, fetchWithTimeout } =
    useContext(TableContext);
  const [refresh, setRefresh] = useState(false);
  const [allchecked, setAllChecked] = React.useState([]);
  const [radioValue, setRadioValue] = useState("All");
  const heightRef = useRef();
  const [width, setWidth] = useState("");
  const [tableRightBoundary, setTableRightBoundary] = useState(0);
  // Extracting unique values based on the specified filter index
  const uniqueValues = [
    ...new Set(props.table1?.map((row) => row[props.FilterIndex])),
  ];
  const [uniqueValues2, setUniqueValues2] = useState([
    ...new Set(props.table1?.map((row) => row[props.FilterIndex2])),
  ]);
  const [selectedFilter1, setSelectedFilter1] = useState("All");
  const [selectedFilter2, setSelectedFilter2] = useState("All");
  //   console.log(uniqueValues)
  /**
   * Handles filter change event to update the displayed rows based on the selected filter.
   */
  const handleFilterChange = () => {
    const filterOptions = document?.querySelectorAll('input[type="radio"]');
    const table = document?.getElementById("data-table");
    const tbody = table?.querySelector("tbody");
    // Attach event listener to radio buttons
    filterOptions.forEach((radio) => {
      radio.addEventListener("change", () => {
        const selectedValue = radio.value;
        setRadioValue(selectedValue);
        var ele = document.getElementsByName("checkbox");
        for (var i = 0; i < ele.length; i++) {
          ele[i].checked = false;
        }
        document.getElementById("allcheckbox").checked = false;
        setAllChecked([]);
        // Loop through table rows and hide/show based on the selected radio button
        const rows = tbody?.querySelectorAll("tr");
        rows?.forEach((row) => {
          const genderCell = row?.querySelector(
            `td:nth-child(${props.FilterIndex + 1})`
          );
          if (
            selectedValue === "All" ||
            genderCell.textContent === selectedValue
          ) {
            row.style.display = "";
          } else {
            row.style.display = "none";
          }
        });
      });
    });
    // Function htmlFor sorting the table columns (you may already have this function)
    function sortTable3(columnIndex) {
      // Your sorting logic here
    }
  };
  /**
   * Handles the delete action for a specific row.
   *
   * @param {Object} value - The data associated with the row to be deleted.
   */

  const PendingToastContent = ({ toastId, text }) => (
    <div
      style={{
        position: "relative",
        padding: "10px",
        fontSize: "15px",
        marginLeft: "5px",
        paddingLeft: "0px",
      }}
    >
      {text}
      <span
        onClick={() => toast.dismiss(toastId)}
        style={{
          position: "absolute",
          top: 0,
          right: -1,
          // margin: '0.5rem',
          cursor: "pointer",
          fontSize: "15px",
        }}
      >
        ✖
      </span>
    </div>
  );

  const handleDelete = (value) => {
    fetchWithTimeout(`${process.env.REACT_APP_BACKEND_URI}/delete`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(value),
    });
    props.setshowCommit(true);
  };
  /**
   * Changes the background color of a row to indicate selection.
   *
   * @param {string} e - The identifier of the row.
   */
  const makeRed = (e) => {
    const row = document.getElementById(e);
    row.style.background = "rgba(233, 178, 183, 1)";
  };
  /**
   * Handles the change event for checkboxes in each row.
   *
   * @param {Object} e - The checkbox change event.
   * @param {Object} value - The data associated with the row.
   */
  const handleCheckChange = (e, value) => {
    if (e.target.checked) {
      setAllChecked([...allchecked, value]);
    } else {
      setAllChecked(allchecked.filter((item) => item !== value));
    }
    console.log(allchecked);
  };
  const table = document?.getElementById("data-table");
  const tbody = table?.querySelector("tbody");
  const rows = tbody?.querySelectorAll("tr");
  // console.log(rows)
  const handleAllCheckChange = (e, value) => {
    var ele = document.getElementsByName("checkbox");
    console.log(ele);

    if (e.target.checked) {
      if (selectedFilter1 === "All" && selectedFilter2 === "All") {
        setAllChecked(props.table1);
      } else if (selectedFilter1 === "All") {
        console.log("selectedFilter1 === All");
        // Filter the table based on selectedFilter2
        setAllChecked(
          props.table1?.filter((item) => {
            return item[5] === selectedFilter2;
          })
        );
      } else if (selectedFilter2 === "All") {
        console.log("selectedFilter2 === All");
        // Filter the table based on selectedFilter1
        setAllChecked(
          props.table1?.filter((item) => {
            return item[0] === selectedFilter1;
          })
        );
      } else {
        console.log("selectedFilter1 not  All");
        // Filter the table based on both selectedFilter1 and selectedFilter2
        setAllChecked(
          props.table1?.filter((item) => {
            return item[0] === selectedFilter1 && item[5] === selectedFilter2;
          })
        );
      }
      for (var i = 0; i < ele.length; i++) {
        ele[i].checked = true;
      }
    } else {
      for (var i = 0; i < ele.length; i++) {
        ele[i].checked = false;
      }
      setAllChecked([]);
    }

    console.log("allchecked", allchecked);
  };
  /**
   * Handles the click event for the approve button.
   * Sends a POST request to the server to approve the selected items.
   * Updates the progress, displays success or failure toasts, and refreshes the data.
   * @returns {Promise<void>} A promise that resolves when the approval process is complete.
   */
  const handleApproveClick = async () => {
    props.setProgress(10);
    props.setProgress(30);

    // toast.info("Your approval request is in progress.", {
    //   position: "top-center",
    //   autoClose: 3000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "light",
    // });

    try {
      const fetchResponse = await fetchWithTimeout(
        `${process.env.REACT_APP_BACKEND_URI}/approve`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            checked_rows : allchecked,
            approver : graphData?.displayName}),
        }
      );
      console.log("Approve Result ", fetchResponse["status"]);
      if (!fetchResponse.ok) {
        throw new Error(`HTTP error! Status: ${fetchResponse.status}`);
      }
      const data = await fetchResponse.json();
      
      
      setRefresh(!refresh);
      setAllChecked([]);
      await props.getData();
      refreshCheckboxes();
      await getCount();
      if (data.already_approved.length > 0){
        data.already_approved.forEach((item, index) => {
          if (item[2] == null){
            const toastMessage = `Request ${item[0]} - ${item[1]} is already ${item[4]} by ${item[3]}`;
            toast.error(toastMessage, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
          else{
            const toastMessage = `Request ${item[0]} - ${item[1]} :: ${item[2]} is already ${item[4]} by ${item[3]}`;
            toast.error(toastMessage, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        });
      }

      if (data.successful_final.length > 0) {
        // data.successful_final.forEach((item, index) => {
        //   const toastMessage = ` Approved ${item[0]} - ${item[1]}`;
        //   toast.success(toastMessage, {
        //     position: "top-center",
        //     autoClose: 3000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     theme: "light",
        //   });
        // });
        if (data.successful_final.length == 1) {
          const toastMessage = data.successful_final[0][2] == null 
                              ? `Approved ${data.successful_final[0][0]} - ${data.successful_final[0][1]}` 
                              : `Approved ${data.successful_final[0][0]} - ${data.successful_final[0][1]} :: ${data.successful_final[0][2]}`;
          toast.success(
            toastMessage,
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        }

        if (data.successful_final.length > 1) {
          const toastMessage = data.successful_final[0][2] == null 
                              ? `Approved ${data.successful_final[0][0]} - ${
                                    data.successful_final[0][1]
                                  }  & ${data.successful_final.length - 1} more requests`
                              : `Approved ${data.successful_final[0][0]} - ${
                                    data.successful_final[0][1] 
                                  } :: ${data.successful_final[0][2]} & ${data.successful_final.length - 1} more requests`;
          toast.success(
            toastMessage,
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        }

        props.setProgress(70);
        await fetchOwnership();
      }

      if (data.failed_final.length > 0) {
        // data.failed_final.forEach((item, index) => {
        //   const toastMessage = `Failed ${item[0]} - ${item[1]}`;
        //   toast.error(toastMessage, {
        //     position: "top-center",
        //     autoClose: 3000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     theme: "light",
        //   });
        // });

        if (data.failed_final.length == 1) {
          toast.error(
            `Failed ${data.failed_final[0][0]} - ${data.failed_final[0][1]}`,
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        }

        if (data.failed_final.length > 1) {
          toast.success(
            `Approved ${data.failed_final[0][0]} - ${
              data.failed_final[0][1]
            }  & ${data.failed_final.length - 1} more requests`,
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        }
      }
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        refreshCheckboxes();
        // Trigger failure toast for unexpected errors
        toast.error("An unexpected error occurred", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.error("Unexpected error:", error);
      }
    }
  };
  /**
   * Handles the discard click event.
   * @async
   * @function handleDiscardClick
   * @returns {Promise<void>}
   */
  const handleDiscardClick = async () => {
    props.setProgress(10);
    props.setProgress(30);
    try {
      const response = await fetchWithTimeout(`${process.env.REACT_APP_BACKEND_URI}/discard`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        // body: JSON.stringify(allchecked),
        body: JSON.stringify({
          checked_rows : allchecked,
          approver : graphData?.displayName}),
      });
      const data = await response.json();
     
      props.setProgress(70);
      await fetchOwnership();
      setAllChecked([]);

      // toast.success("Discarded Successfully", {
      //   position: "top-center",
      //   autoClose: 3000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
      setRefresh(!refresh);
      await props.getData();
      refreshCheckboxes();
      await getCount();
      if (data.already_approved.length > 0){
        data.already_approved.forEach((item, index) => {
          const toastMessage = item[2] == null ? `Request ${item[0]} - ${item[1]} is already ${item[4]} by ${item[3]}` : `Request ${item[0]} - ${item[1]} :: ${item[2]} is already ${item[4]} by ${item[3]}`;
          toast.error(toastMessage, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
      }

      if (data.discarded_successfully.length > 0) {
        if (data.discarded_successfully.length == 1) {
          const toastMessage = data.discarded_successfully[0][2] == null ? `Discarded ${data.discarded_successfully[0][0]} - ${data.discarded_successfully[0][1]}` : `Discarded ${data.discarded_successfully[0][0]} - ${data.discarded_successfully[0][1]} :: ${data.discarded_successfully[0][2]} `;
          toast.success(
            toastMessage,
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        }

        if (data.discarded_successfully.length > 1) {
          const toastMessage = data.discarded_successfully[0][2] == null ? `Discarded ${data.discarded_successfully[0][0]} - ${
              data.discarded_successfully[0][1] }  & ${data.discarded_successfully.length - 1} more requests` : `Discarded ${data.discarded_successfully[0][0]} - ${
              data.discarded_successfully[0][1] } :: ${data.discarded_successfully[0][2]}  & ${data.discarded_successfully.length - 1} more requests`;
          toast.success(
            toastMessage,
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        }

        props.setProgress(70);
        await fetchOwnership();
      }
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        refreshCheckboxes();
        toast.error("Server Error", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };
  /**
   * Refreshes all checkboxes by unchecking them.
   */
  const refreshCheckboxes = () => {
    setSelectedFilter1("All");
    setSelectedFilter2("All");
    var ele = document.getElementsByName("checkbox");
    for (var i = 0; i < ele.length; i++) {
      ele[i].checked = false;
    }
    document.getElementById("allcheckbox").checked = false;
    setAllChecked([]);
    document.getElementById("All").checked = true;
    document.getElementById("All2").checked = true;
  };
  /**
   * Sets the width of the table based on the window's inner width.
   */
  const getDimensions = () => {
    if (window.innerWidth >= 2048) {
      setWidth("173px");
    }
    if (window.innerWidth >= 1920 && window.innerWidth < 2048) {
      setWidth("108px");
    }
    if (window.innerWidth >= 1707 && window.innerWidth < 1920) {
      setWidth("33px");
    }
    if (window.innerWidth >= 1536 && window.innerWidth < 1707) {
      setWidth("31px");
    }
  };

  const updateButtonPosition = () => {
    const tableContainer = document.getElementById("data-table");
    if (tableContainer) {
      const newTableRightBoundary =
        tableContainer.getBoundingClientRect().left +
        tableContainer.offsetWidth;
      setTableRightBoundary(newTableRightBoundary);
    }
  };
  useLayoutEffect(() => {
    updateButtonPosition();
    setUniqueValues2([
      ...new Set(props.table1?.map((row) => row[props.FilterIndex2])),
    ]);
  }, [props.table1]);
  useLayoutEffect(() => {
    // Initial update
    // updateButtonPosition();
    getDimensions();
    // setTimeout(() => {
    //   getToken()
    // }, 2000);
    // getToken()
    const handleResize = () => {
      // setWindowWidth(window.innerWidth);
      getDimensions();
      updateButtonPosition();
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // Function to retrieve data from the server
  /**
   * Fetches data from the server and updates the state with the retrieved data.
   * @returns {Promise<void>} A promise that resolves when the data is fetched and the state is updated.
   */
  // const getData = async () => {
  //   props.setProgress(10);
  //   try {
  //     await fetchWithTimeout(
  //       `${process.env.REACT_APP_BACKEND_URI}/approvechanges?ciowner=${graphData?.displayName}`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       }
  //     )
  //       .then((res) => res.json())
  //       .then((data) => {
  //         setTable1(data);
  //         props.setProgress(70);

  //         //console.log(data)
  //       });
  //   } catch (error) {
  //     if (error.message === "Timeout") {
  //       toast.error("Request Timed Out", {
  //         position: "top-center",
  //         autoClose: 3000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "light",
  //       });
  //     }
  //   }

  //   props.setProgress(100);
  // };
  useEffect(() => {
    const filterTable = () => {
      const table = document.getElementById("data-table");
      if (table != null && typeof table !== "undefined") {
        const tbody = table?.querySelector("tbody");
        const rows = tbody?.querySelectorAll("tr");

        rows.forEach((row) => {
          const cell1 = row.querySelector(
            `td:nth-child(${props.FilterIndex + 1})`
          );
          const cell2 = row.querySelector(
            `td:nth-child(${props.FilterIndex2 + 2})`
          );

          const matchFilter1 =
            selectedFilter1 === "All" || cell1.textContent === selectedFilter1;
          const matchFilter2 =
            selectedFilter2 === "All" || cell2.textContent === selectedFilter2;

          if (matchFilter1 && matchFilter2) {
            row.style.display = "";
          } else {
            row.style.display = "none";
          }
        });
      }
    };
    filterTable();
  }, [selectedFilter1, selectedFilter2, props.FilterIndex, props.FilterIndex2]);
  useEffect(() => {
    const filteredTable1 =
      selectedFilter1 === "All"
        ? props.table1
        : props.table1?.filter(
            (row) => row[props.FilterIndex] === selectedFilter1
          );
    setUniqueValues2([
      ...new Set(filteredTable1?.map((row) => row[props.FilterIndex2])),
    ]);
    setSelectedFilter2("All");
    const allRadioButton2 = document.querySelector(
      'input[name="filter2"][value="All"]'
    );
    if (allRadioButton2) {
      allRadioButton2.checked = true;
    }
  }, [selectedFilter1]);
  return (
    <div>
      {/* Conditional rendering based on the presence of data */}
      {props.table1?.length > 0 && (
        <div style={{ position: "relative" }}>
          <div
            style={{
              margin: "auto",
              width: "95%",
              marginTop: "70px",
              borderBottom: "2px solid #87CEEB",
              borderLeft: "2px solid #87CEEB",
              maxWidth: "fit-content",
            }}
          >
            <div
              style={{
                float: "left",
                border: "2px solid #87CEEB",
                width: "200px",
                borderBottom: "none",
                borderLeft: "none",
                paddingTop: "7px",
                borderRight: "none",
                maxHeight: "100%",
              }}
            >
              <div style={{ borderBottom: "2px solid #87CEEB" }}>
                <label htmlFor="ciTypeFilter" style={{ margin: "4px" }}>
                  Filter By CI Type:
                </label>
              </div>
              <div
                className="filters"
                style={{ padding: "7px", overflowY: "auto", height: "130px" }}
              >
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="All"
                    name="filter"
                    id="All"
                    onChange={() => {
                      setSelectedFilter1("All");
                      var ele = document.getElementsByName("checkbox");
                      for (var i = 0; i < ele.length; i++) {
                        ele[i].checked = false;
                      }
                      document.getElementById("allcheckbox").checked = false;
                      setAllChecked([]);
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="All"
                    style={{ fontSize: "16px" }}
                  >
                    All
                  </label>
                </div>
                {uniqueValues.map((value, key) => {
                  return (
                    <div className="form-check" key={key}>
                      <input
                        className="form-check-input"
                        type="radio"
                        value={value}
                        id={value}
                        name="filter"
                        onChange={() => {
                          setSelectedFilter1(value);
                          var ele = document.getElementsByName("checkbox");
                          for (var i = 0; i < ele.length; i++) {
                            ele[i].checked = false;
                          }
                          document.getElementById(
                            "allcheckbox"
                          ).checked = false;
                          setAllChecked([]);
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={value}
                        style={{ fontSize: "16px" }}
                      >
                        {value}
                      </label>
                    </div>
                  );
                })}
              </div>
              <div
                style={{ borderBottom: "2px solid #87CEEB", marginTop: "10px" }}
              >
                <label htmlFor="ciTypeFilter2" style={{ margin: "4px" }}>
                  Filter By Category:
                </label>
              </div>
              <div
                className="filters"
                style={{ padding: "7px", overflowY: "auto", height: "127px" }}
              >
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="All"
                    name="filter2"
                    id="All2"
                    onChange={() => {
                      setSelectedFilter2("All");
                      var ele = document.getElementsByName("checkbox");
                      for (var i = 0; i < ele.length; i++) {
                        ele[i].checked = false;
                      }
                      document.getElementById("allcheckbox").checked = false;
                      setAllChecked([]);
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="All2"
                    style={{ fontSize: "16px" }}
                  >
                    All
                  </label>
                </div>
                {uniqueValues2.map((value, key) => (
                  <div className="form-check" key={key}>
                    <input
                      className="form-check-input"
                      type="radio"
                      value={value}
                      id={value}
                      name="filter2"
                      onChange={() => {
                        setSelectedFilter2(value);
                        var ele = document.getElementsByName("checkbox");
                        for (var i = 0; i < ele.length; i++) {
                          ele[i].checked = false;
                        }
                        document.getElementById("allcheckbox").checked = false;
                        setAllChecked([]);
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={value}
                      style={{ fontSize: "16px" }}
                    >
                      {value}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div
              className="table-container"
              ref={heightRef}
              // style={{
              //   marginLeft: "20px",
              //   overflowY: "auto",
              //   minHeight: "200px",
              //   maxHeight: "350px",
              //   maxWidth: "fit-content",
              // }}
            >
              <table id="data-table" className="custom-table">
                <thead>
                  <tr>
                    <th style={{ border: "2px solid #87CEEB" }}>
                      {props.Header1}
                    </th>
                    <th style={{ border: "2px solid #87CEEB" }}>
                      {props.Header2}
                    </th>
                    <th style={{ border: "2px solid #87CEEB" }}>
                      {props.Header7}
                    </th>
                    <th style={{ border: "2px solid #87CEEB" }}>
                      {props.Header3}
                    </th>
                    <th style={{ border: "2px solid #87CEEB" }}>
                      {props.Header4}
                    </th>
                    <th style={{ border: "2px solid #87CEEB" }}>
                      {props.Header6}
                    </th>
                    {/* <th style={{ border: "2px solid #87CEEB" }}>
                {props.Header5}
                </th> */}
                    <th style={{ border: "2px solid #87CEEB" }}>
                      {" "}
                      <input
                        type="checkbox"
                        id="allcheckbox"
                        onChange={(e) => {
                          handleAllCheckChange(e);
                        }}
                      ></input>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {props.table1?.map((value, key) => {
                    return (
                      <tr key={key} id={key}>
                        <td style={{ border: "2px solid #87CEEB" }}>
                          {value[0]}
                        </td>
                        <td style={{ border: "2px solid #87CEEB" }}>
                          {value[1]}
                        </td>
                        <td style={{ border: "2px solid #87CEEB" }}> {value[6]}</td>
 
                        <td style={{ border: "2px solid #87CEEB" }}>
                          {value[2]}
                        </td>
                        <td style={{ border: "2px solid #87CEEB" }}>
                          {value[3]}
                        </td>
                        <td
                          style={{
                            border: "2px solid #87CEEB",
                            display: "none",
                          }}
                        >
                          {value[4]}
                        </td>
                        <td style={{ border: "2px solid #87CEEB" }}>
                          {value[5]}
                        </td>
                        <td
                          style={{
                            border: "2px solid #87CEEB",
                            textAlign: "center",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="checkbox"
                            name="checkbox"
                            onChange={(e) => {
                              handleCheckChange(e, value);
                            }}
                          ></input>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          {tableRightBoundary > 0 && (
            <div
              style={{
                position: "absolute",
                marginTop: "10px",
                // top: "355px",
                right: window.innerWidth - tableRightBoundary,
              }}
            >
              <Button
                appearance="alt"
                fit="medium"
                id="primary"
                justifyItems="center"
                label="Approve"
                name="primary"
                onClick={async function noRefCheck() {
                  if (allchecked.length == 0) {
                    toast.error("Please select a row to approve", {
                      position: "top-center",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                  } else {
                    await toast.promise(handleApproveClick(), {
                      pending: {
                        render({ id }) {
                          return (
                            <PendingToastContent
                              toastId={id}
                              text="Your approve request is in progress"
                            />
                          );
                        },
                        closeOnClick: true,
                        icon: true,
                      },
                      // success: "Approved Successfully",
                      // error: "Unexpected Error Occured",
                    });
                    // handleApproveClick();
                  }
                }}
                title="Button"
                variant="filled"
              />
              <Button
                appearance="error"
                fit="medium"
                id="primary"
                justifyItems="center"
                label="Discard"
                name="primary"
                onClick={async function noRefCheck() {
                  if (allchecked.length == 0) {
                    toast.error("Please select a row to discard", {
                      position: "top-center",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                  } else {
                    await toast.promise(handleDiscardClick(), {
                      pending: {
                        render({ id }) {
                          return (
                            <PendingToastContent
                              toastId={id}
                              text="Your discard request is in progress"
                            />
                          );
                        },
                        closeOnClick: true,
                        icon: true,
                      },

                      // success: "Discarded Successfully",
                      // error: "Unexpected Error Occured",
                    });
                    // handleDiscardClick()
                  }
                }}
                title="Button"
                variant="filled"
                style={{ marginLeft: "10px", marginRight: "0px" }}
              />
            </div>
          )}
        </div>
      )}
      {props.table1?.length == 0 && (
        <div style={{ textAlign: "center", marginTop: "200px" }}>
          No relations to approve
        </div>
      )}
    </div>
  );
}
